
































import Vue from "vue";
import ViewJobLoading from "@/components/recruiter/jobs/ViewJobLoading.vue";
import EditJobDetails from "@/components/recruiter/jobs/EditJobDetails.vue";
import { parseStrToInt } from "@/utils/global";
import { Jobs } from "@/interfaces/data_objects/jobs";
import { UpdateJobPayload } from "@/store/modules/recruiter/interfaces";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  FETCH_MATCHING_CANDIDATES,
  JOBS_POSTED_BY_RECRUITER,
  UPDATE_JOB
} from "@/store/modules/recruiter/constants";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import {
  FETCH_SKILLS_GROUP,
  GET_JOB_BY_ID
} from "@/store/modules/common/constants";

export default Vue.extend({
  name: "EditJob",
  beforeRouteEnter(to, from, next) {
    const param_id = to.params.id;
    if (!param_id || !parseStrToInt(param_id)) next(false);
    else next();
  },
  beforeRouteUpdate(to, from, next) {
    const param_id = to.params.id;
    if (!param_id || !parseStrToInt(param_id)) next(false);
    else next();
  },
  components: { EditJobDetails, ViewJobLoading },
  data() {
    return {
      loading: true,
      job: null as null | Jobs,
      update_job_loading: false
    };
  },
  computed: {
    ...mapGetters("recruiter", {
      get_job_with_candidates: FETCH_MATCHING_CANDIDATES
    })
  },
  async mounted() {
    this.loading = true;
    const jid = parseStrToInt(this.$route.params.id);
    if (!jid) {
      this.root_error("Invalid Job");
      await this.$router.push("/recruiter/jobs");
      return;
    }
    // If the job exists in store => get
    const response = this.get_job_with_candidates(jid);
    if (response) {
      this.job = response;
      this.loading = false;
      return;
    }
    // If the job not exists in store => fetch from backend
    await this.process_job_from_backend(jid);
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("recruiter", {
      update_job_in_db: UPDATE_JOB
    }),
    ...mapActions("common", {
      fetch_job_by_id: GET_JOB_BY_ID,
      fetch_skill_group: FETCH_SKILLS_GROUP
    }),
    ...mapMutations("recruiter", {
      reset_top_posted_jobs: JOBS_POSTED_BY_RECRUITER
    }),
    /**
     * Function to update job
     * @param job: Job details to update
     */
    async update_job(job: Jobs) {
      this.update_job_loading = true;
      // Update job
      const payload: UpdateJobPayload = {
        job_id: job.jid,
        job_title: job.job_title,
        job_description: job.job_description ?? "",
        job_sector: job.sector ?? "",
        job_experience: job.experience ?? "",
        job_employment_type: job.employment_type ?? "",
        job_skills_list: JSON.stringify(job.skills_list)
      };
      const response = await this.update_job_in_db(payload);
      // If the job updated successfully
      if (response) {
        this.root_notification(this.$t("success-messages.job-updated-success"));
        this.reset_top_posted_jobs({ total: 0, results: [] });
        await this.$router.push(`/recruiter/jobs`);
      } else {
        this.root_error(this.$t("errors.job-updated-failed"));
      }
      this.update_job_loading = false;
    },
    async process_job_from_backend(jid: number) {
      // Fetching job
      const response = await this.fetch_job_by_id(jid);
      if (!response) {
        this.root_error("Invalid Job");
        await this.$router.push(`/recruiter/jobs/${jid}`);
        this.loading = false;
        return;
      }
      // Fetching skills group
      let skills: string | string[] = response.skills_list;
      if (response.skills_list && typeof response.skills_list === "string") {
        skills = JSON.parse(response.skills_list);
        if (typeof skills === "string") skills = eval(skills);
      }
      response.skills_list = await this.fetch_skill_group(skills);
      this.job = response;
      this.loading = false;
    }
  }
});
